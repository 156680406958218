import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  container,
  people as peopleClass,
  person as personClass,
  title,
} from "./people.module.scss";
import { IQueryAllResult } from "../models/query-all-result.model";
import { IPerson } from "../models/person.model";
import { getNodes } from "../utils/get-nodes";

import MainLayout from "../layouts/main-layout";
import PersonCard from "../components/molecules/person-card";
import Title from "../components/atoms/title";

interface IPeopleProps {
  className: string;
  readonly data: {
    allStrapiPerson: IQueryAllResult<IPerson>;
  };
}

const People: React.FC<IPeopleProps> = ({ className = "", data }) => {
  const { allStrapiPerson } = data;

  const people = getNodes(allStrapiPerson);

  const { t } = useTranslation();
  return (
    <MainLayout titleSeo={t("people.title")} descriptionSeo={t("people.title")}>
      <Title Tag="h1" size="large" className={title}>
        {t("people.title")}
      </Title>

      <div className={peopleClass}>
        {people.map((person, index) => {
          const {
            Name,
            Surname,
            slug,
            Degree,
            Position_1,
            Position_2,
            img_people,
          } = person;

          return (
            <PersonCard
              className={personClass}
              name={Name}
              surname={Surname}
              degree={Degree}
              position1={Position_1}
              position2={Position_2}
              slug={slug}
              key={index}
              gatsbyImageAll={img_people}
            />
          );
        })}
      </div>

      <div className={container}></div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allStrapiPerson(
      sort: { fields: Sequence, order: ASC }
      filter: { locale: { eq: $language } }
    ) {
      edges {
        node {
          Surname
          slug
          Name
          Degree
          Position_1
          Position_2
          Sequence
          img_people {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`;

export default People;
