import React, { useEffect } from "react";
import { Link } from "gatsby";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  container,
  img,
  title,
  detail,
  fakeButton,
  details,
} from "./person-card.module.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useTranslation } from "react-i18next";

interface IPersonCardProps {
  className: string;
  name: string;
  surname: string;
  slug: string;
  degree?: string;
  position1?: string;
  position2?: string;
  gatsbyImageAll: any;
}

const PersonCard: React.FC<IPersonCardProps> = ({
  className = "",
  name,
  surname,
  slug,
  degree,
  position1,
  position2,
  gatsbyImageAll,
}) => {
  const { alternativeText, localFile } = gatsbyImageAll || {};
  const gatsbyImage = getImage(localFile);
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <Link
      to={`/${slug}`}
      className={`${className} ${container}`}
      data-aos="fade-up"
    >
      {gatsbyImage && (
        <GatsbyImage
          alt={alternativeText}
          image={gatsbyImage}
          className={img}
          objectFit={"cover"}
          objectPosition={"top"}
        />
      )}
      <div className={details}>
        <h2 className={title}>
          {name} {surname}
        </h2>
        {degree && <p className={detail}>{degree}</p>}
        <p className={detail}>{position1}</p>
        <p className={detail}>{position2}</p>
        <span className={fakeButton}>{t("see.profile")}</span>
      </div>
    </Link>
  );
};

export default PersonCard;
